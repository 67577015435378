/// <reference path="cookies.ts" />

// Supported languages
const enum Language {
    NL = "nl",
    FR = "fr",
    EN = "en",
}

// Supported languages
const LANGUAGES = [Language.NL, Language.FR, Language.EN];

// Current language
let LANG: Language = Language.NL;

// isSupportedLang returns true if `lang` is a supported language.
function isSupportedLang(lang: string) {
    return (LANGUAGES as string[]).indexOf(lang) !== -1;
};

// _getDefaultLang returns the default language to use for the interface, based
// on whatever cookies or browser preferences are set.
function _getDefaultLang(): string {
    // 1. Cookie
    const cookieLang = getCookie("lang");
    if (cookieLang)
        return cookieLang;
    // 2. Browser preferences
    // extractLang extracts a language from a locale such as "nl-BE", "en-us",
    // "FR_FR", or "en" (respectively "nl", "en", "fr", and "en").
    const extractLang = (locale: string): string => {
        if (!locale)
            return "";
        if (locale.indexOf("-") !== -1)
            locale = locale.split("-")[0];
        if (locale.indexOf("_") !== -1)
            locale = locale.split("_")[0];
        return locale.toLowerCase();
    };
    // * navigator.languages returns an array of the user's preferred languages
    //   as set in the browser settings. This is the best option to use, but
    //   only supported by newer browsers.
    // * navigator.language is a widely supported setting that returns either
    //   the user's preferred language (most browsers) or the language of the
    //   browser UI (Chrome)
    // * navigator.userLanguage is IE-specific and returns the user's preferred
    //   language (not necessarily that of the browser UI)
    // Sources:
    // * https://zzz.buzz/2016/01/13/detect-browser-language-in-javascript/
    // * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages
    // * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language
    // * https://stackoverflow.com/a/25603630/8137
    if (navigator.languages) {
        // Find first supported language
        const langs = navigator.languages.map(extractLang)
        const pref = _findInArray(langs, isSupportedLang);
        if (pref !== undefined)
            return pref;
    }
    if ("language" in navigator && isSupportedLang(navigator.language))
        return navigator.language;
    if ("userLanguage" in navigator && isSupportedLang((navigator as any)["userLanguage"]))
        return (navigator as any)["userLanguage"];
    // 3. Default
    return "en";
};

// setLang sets the language of the GUI to `langStr`.
// If `langStr` is not given, sets the language to the default language.
function setLang(langStr?: string): void {
    if (!langStr)
        langStr = _getDefaultLang();

    if (isSupportedLang(langStr))
        LANG = langStr as Language;
    else
        LANG = Language.EN;

    setCookie("lang", LANG);

    // Update translatable texts
    d3.selectAll("[data-text]").html(function (_d, _i) {
        const el = d3.select(this);
        const key = el.attr("data-text");
        return text(key as LocText);
    });

    // Update <html>'s lang attribute
    d3.select("html").attr("lang", LANG);

    // Set datetime locales
    d3.timeFormatDefaultLocale(D3_LOCALES[LANG]);
    TIME_FORMAT = d3.timeFormat(text("time_format"));

    // Update language selector
    d3.selectAll("#lang-selector > li").classed("active", false);
    d3.selectAll("#lang-selector > li[data-val='" + LANG + "']").classed(
        "active", true);
};

// switchLang set the language of the GUI to `lang`, and updates it.
function switchLang(lang: string) {
    setLang(lang);
    updateUI();
};

// A piece of translated text
interface TranslatedText {
    nl: string;
    fr: string;
    en: string;
}

// Names of the localized text fragments
type LocText = "title" | "head" | "last_update" | "now" | "forecast" |
    "temp_title" | "precip_title" | "precip_label1" | "precip_label2" |
    "time_format" | "info" | "info_text";

// text returns the text fragment with `key` in the current language.
function text(key: LocText) {
    if (key in TEXT[LANG])
        return TEXT[LANG][key as LocText];
    else
        return "";
};

// Translated text fragments
const TEXT: {[L in Language]: {[T in LocText]: string}} = {
    "nl": {
        "title": "Het weer in Brussel",
        "head":  "Het weer in Brussel",
        "last_update": "om %-H:%M",
        "now": "Nu",
        "forecast": "Voorspelling",
        "temp_title":  "Temperatuur (\u00B0C)",
        "precip_title": "Neerslag (mm/u)",
        "precip_label1": "{}% kans",
        "precip_label2": "op {} mm",
        "time_format": "%a %-Hu", // e.g. ma 8u
        "info": "Info",
        "info_text": '<p>Deze website toont de waarnemingen voor het weer op dit moment in Brussel (Belgi&euml;). We tonen ook de weersverwachtingen voor de komende drie dagen: temperatuur, neerslag (regen, sneeuw, hagel) en bewolking.</p>' +
        '<p>De weergegevens komen van <a href="https://pirateweather.net/">Pirate Weather</a>, die op hun beurt data combineren van een heleboel bronnen, zoals verschillende weermodellen van de <a href="http://www.noaa.gov/">National Oceanic and Atmospheric Administration (NOAA)</a> (zowat het Amerikaanse KMI). De NOAA verzamelt wereldwijd gegevens uit weerstations, in het bijzonder ook in Ukkel en Brussels Airport (en vliegbasis Melsbroek). Dit moet leiden tot een zo accuraat mogelijke voorspelling.</p>' +
        '<p>Heb je vragen, opmerkingen of feedback? Mail deze dan naar <a href="mailto:info@weer.brussels">info@weer.brussels</a>.</p>' +
        '<p>Naast deze website maakte ik ook <a href="https://nuopderadio.be/">nu op de radio, met de playlists van de VRT radiostations</a>.</p>' +
        '<p>Weather data powered by <a href="https://pirateweather.net/">Pirate Weather</a>. Iconen van <a href="https://www.iconfinder.com/iconsets/weather-color-2">Yun Liu</a>.</p>',
    },
    "fr": {
        "title": "Le météo à Bruxelles",
        "head": "Le météo à Bruxelles",
        "last_update": "à %-H:%M",
        "now": "Maintenant",
        "forecast": "Prévisions",
        "temp_title": "Température (\u00B0C)",
        "precip_title": "Précipitations (mm/h)",
        "precip_label1": "{}% probabilité",
        "precip_label2": "de {} mm",
        "time_format": "%a %-Hh",
        "info": "Info",
        "info_text": '<p>Ce site web affiche les observations météorologiques actuelles pour Bruxelles (en Belgique). On présente aussi les prévisions pour les trois prochains jours, comprenant la température, les précipitations (pluie, neige, grêle) et la couverture nuageuse.</p>' +
        '<p>Les données météorologiques sont fournies par <a href="https://pirateweather.net/">Pirate Weather</a>, qui se servent à leur tour de données provenant de différentes sources, y compris plusieurs modèles météorologiques réalisés par la <a href="http://www.noaa.gov/">National Oceanic and Atmospheric Administration (NOAA)</a> (l&rsquo;organisme météorologique officiel des États-Unis). La NOAA recueille des données de stations météorologiques réparties dans le monde entier, notamment à Uccle et à Brussels Airport (et à la base aérienne de Melsbroek). Cela devrait produire des prévisions aussi précises que possible.</p>' +
        '<p>Si vous avez des questions ou des remarques, nous vous invitons à nous les envoyer par courriel à <a href="mailto:info@weer.brussels">info@weer.brussels</a>.</p>' +
        '<p>N&rsquo;hésitez pas à visiter notre autre site web, <a href="https://nuopderadio.be/">nu op de radio</a>, listant les playlists des stations de radio du VRT.</p>' +
        '<p>Weather data powered by <a href="https://pirateweather.net/">Pirate Weather</a>. Pictogrammes de <a href="https://www.iconfinder.com/iconsets/weather-color-2">Yun Liu</a>.</p>',
    },
    "en": {
        "title": "The weather in Brussels",
        "head": "The weather in Brussels",
        "last_update": "at %-H:%M",
        "now": "Now",
        "forecast": "Forecast",
        "temp_title": "Temperature (\u00B0C)",
        "precip_title": "Precipitation (mm/h)",
        "precip_label1": "{}% chance",
        "precip_label2": "of {} mm",
        "time_format": "%a %-Hh",
        "info": "Info",
        "info_text": '<p>This website shows the current weather observations and forecasts for Brussels, Belgium, consisting of temperature, precipitation (rain, snow, hail), and cloud coverage for the next three days.</p>' +
        '<p>The weather data is provided by <a href="https://pirateweather.net/">Pirate Weather</a>, who in turn rely upon a number of sources, including several weather models made by the <a href="http://www.noaa.gov/">National Oceanic and Atmospheric Administration (NOAA)</a> (the official weather organisation of the US). The NOAA has weather stations all over the world, notably in Uccle and at Brussels Airport (and Melsbroek Air Base). This should lead to a forecast that is as accurate as possible.</p>' +
        '<p>If you have questions, comments, or feedback, you can mail us at <a href="mailto:info@weer.brussels">info@weer.brussels</a>.</p>' +
        '<p>I also made a website with the <a href="https://nuopderadio.be/">playlist of the radio stations of VRT</a>, the Flemish public broadcaster.</p>' +
        '<p>Weather data powered by <a href="https://pirateweather.net/">Pirate Weather</a>. Icons by <a href="https://www.iconfinder.com/iconsets/weather-color-2">Yun Liu</a>.</p>',
    },
};

// D3 locale info
// Copied (and slightly modified) from d3.js
// (https://github.com/d3/d3-time-format/blob/master/locale/)
const D3_LOCALES: {[L in Language]: d3.TimeLocaleDefinition} = {
    "nl": {
        "dateTime": "%a %e %B %Y %T",
        "date": "%d-%m-%Y",
        "time": "%H:%M:%S",
        "periods": ["AM", "PM"],
        "days": ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
        "shortDays": ["zo", "ma", "di", "wo", "do", "vr", "za"],
        "months": ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
        "shortMonths": ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]
    },
    "fr": {
        "dateTime": "%A, le %e %B %Y, %X",
        "date": "%d/%m/%Y",
        "time": "%H:%M:%S",
        "periods": ["AM", "PM"],
        "days": ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
        "shortDays": ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
        "months": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
        "shortMonths": ["janv", "févr", "mars", "avr", "mai", "juin", "juil", "août", "sept", "oct", "nov", "déc"]
    },
    "en": { // en-GB
        "dateTime": "%a %e %b %X %Y",
        "date": "%d/%m/%Y",
        "time": "%H:%M:%S",
        "periods": ["AM", "PM"],
        "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    }
};

// Time format
// This is updated after the locale has been set.
let TIME_FORMAT: (date: Date) => string = d3.timeFormat("%a %-Hu");
