/// <reference types="d3" />

// _findinArray() method returns the value of the first element in `list` that
// satisfies the provided testing function `predicate`. Otherwise `undefined` is
// returned.
//
// Polyfill for Array.find.
// Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
function _findInArray<T>(list: T[], predicate: (element: T, index?: number, array?: T[]) => boolean): T | undefined {
    for (var i = 0; i < list.length; i++) {
        let value = list[i];
        if (predicate.call(list, value, i, list))
            return value;
    }
    return undefined;
};

// asArray returns a D3 selection containing multiple elements as an array of D3
// selections.
function _d3SelectionAsArray<GElement extends d3.BaseType, Datum, PElement extends d3.BaseType, PDatum>(
    selection: d3.Selection<GElement, Datum, PElement, PDatum>): d3.Selection<GElement, Datum, null, undefined>[] {
    return selection.nodes().map(el => d3.select<GElement, Datum>(el));
};
