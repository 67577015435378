// getCookie returns the value of the cookie with `name`, or null if no such
// cookie exists.
// Source: https://www.quirksmode.org/js/cookies.html
function getCookie(name: string): string | null {
    const nameEq = name + "=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        let c = cookies[i];
        while (c.charAt(0) == " ")
            c = c.substring(1, c.length);
        if (c.indexOf(nameEq) == 0)
            return c.substring(nameEq.length, c.length);
    }
    return null;
};

// setCookie sets a cookie with `name` and `value`, expiring in `days` days.
// Source: https://www.quirksmode.org/js/cookies.html
function setCookie(name: string, value: string, days?: number): void {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
};
