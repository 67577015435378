// Weather data
interface Datum {
    time: Date;
    time_unix: string;
    temp: number;
    precip: number;
    precip_chance: number;
    description: TranslatedText;
    icon: string;
    text_color: string;
    temp_bgcolor: string;
    temp_fgcolor: string;
    precip_color: string;
    sunrise?: Date;
    sunrise_unix?: string;
    sunset?: Date;
    sunset_unix?: string;
    min?: boolean;
    max?: boolean;
}

interface Data {
    current?: Datum;
    prediction: TranslatedText;
    hourly?: Datum[];
    daily?: Datum[];
}

let DATA: Data = {
    current: undefined,
    prediction: {"nl": "", "fr": "", "en": ""},
    hourly: [],
    daily: [],
};

// _indicateDailyMinMax iterates over the hourly data and sets the attributes
// `min` or `max` to `true` for each daily minimum and maximum that should be
// indicated on the chart.
function _indicateDailyMinMax(hourly: Datum[]): void {
    type IGNORE = "IGNORE";
    const IGNORE: IGNORE = "IGNORE";
    // We do not indicate the min/max if it's at the beginning or end of the
    // graph, because: (1) we want to avoid running over the left axis or going
    // out of graph area, and (2) those might not be the real daily min/max, due
    // to incomplete data.
    const doNotIndicate = (d: Datum) => d.time < hourly[3].time ||
        d.time > hourly[hourly.length - 4].time;
    // getDay returns the day of week of a data point, to calculate maxima. Days
    // go from 8:00 to 24:00. (For 24:00, we return the previous day.)
    const getDay = (d: Datum): number | IGNORE => {
        if (d.time.getHours() == 0)
            return (d.time.getDay() + 6) % 7;
            // Instead of -1, do +6 % 7, to stay positive
            else if (d.time.getHours() >= 8)
            return d.time.getDay();
        else
            return IGNORE;
    };
    // getNight returns the day of week of a data point, to calculate minima.
    // Nights go from 20:00 to 10:00. (For 20:00 - 23:59, we return the current
    // day of week, for 0:00 - 10:00 the previous one.)
    const getNight = (d: Datum): number | IGNORE => {
        if (d.time.getHours() >= 20)
            return d.time.getDay();
        else if (d.time.getHours() <= 10)
            return (d.time.getDay() + 6) % 7;
            // Instead of -1, do +6 % 7, to stay positive
        else
            return IGNORE;
    };
    // groupBy returns a map of the elements in `list` grouped using `getKey`.
    function groupBy<K, V>(list: V[], getKey: (t: V) => K): Map<K, V[]> {
        let result = new Map<K, V[]>();
        list.forEach(t => {
            const key: K = getKey(t);
            const current = result.get(key);
            if (current == undefined)
                result.set(key, [t]);
            else
                current.push(t);
        });
        return result;
    }
    // getMax returns the (first) datum with the maximum temperature.
    function getMax(data: Datum[]): Datum {
        let max = data[0].temp;
        let maxDatum = data[0];
        for (const datum of data) {
            if (datum.temp > max) {
                max = datum.temp;
                maxDatum = datum;
            }
        }
        return maxDatum;
    }
    // getMin returns the (first) datum with the minimum temperature.
    function getMin(data: Datum[]): Datum {
        let min = data[0].temp;
        let minDatum = data[0];
        for (const datum of data) {
            if (datum.temp < min) {
                min = datum.temp;
                minDatum = datum;
            }
        }
        return minDatum;
    }
    // hourly = [Datum{time: 2019-02-01T07:00, temp: 7},
    //           Datum{time: 2019-02-01T08:00, temp: 8},
    //           Datum{time: 2019-02-01T09:00, temp: 9}, ...]
    // dayTemps = {5 /* Fri */: [Datum{time: 2019-02-11T08:00, temp: 8},
    //                           Datum{time: 2019-02-11T09:00, temp: 9}, ...]
    //             6 /* Sat */: ...}
    const dayTemps = groupBy(hourly, (d) => getDay(d));
    dayTemps.delete(IGNORE);
    dayTemps.forEach(data => {
        const maxDatum = getMax(data);
        if (doNotIndicate(maxDatum)) return;
        maxDatum.max = true;
    });
    const nightTemps = groupBy(hourly, (d) => getNight(d));
    nightTemps.delete(IGNORE);
    nightTemps.forEach(data => {
        const minDatum = getMin(data);
        if (doNotIndicate(minDatum)) return;
        minDatum.min = true;
    });
};

// processResponse is the callback for calls to /api/forecast.
function processResponse(response: any) {
    // Process data points
    const parseTime = d3.timeParse("%s");
    const processDatum = (f: any): Datum => {
        let time = parseTime(f["time"]);
        if (time == null)
            time = new Date();

        // Round temperatures to 1 decimal, precipitation to 2.
        let temp = f["temperature"] || 0;
        temp = Math.round(temp * 10) / 10;
        let precip = f["precip_volume"] || 0;
        precip = Math.round(precip * 100) / 100;
        // Convert precipitation chance to percentage and round.
        let precip_chance = f["precip_probability"] || 0;
        precip_chance = Math.round(precip_chance * 100);

        let icon = "";
        if (f["icon"])
            icon = "/icons/" + f["icon"] + ".svg";

        const d: Datum = {
            time: time,
            time_unix: f["time"],
            temp: temp,
            precip: precip,
            precip_chance: precip_chance,
            description: f["description"],
            icon: icon,
            text_color: _getBoundingElement(TEMP_COLORS, temp).text,
            temp_bgcolor: _getBoundingElement(TEMP_COLORS, temp).bg,
            temp_fgcolor: _getBoundingElement(TEMP_COLORS, temp).fg,
            precip_color: _getBoundingElement(PRECIP_COLORS, precip).color,
        };

        // daily properties:
        if (f["sunrise"]) {
            let sunrise = parseTime(f["sunrise"]);
            if (sunrise != null) {
                d.sunrise = sunrise;
                d.sunrise_unix = f["sunrise"];
            }
        }
        if (f["sunset"]) {
            let sunset = parseTime(f["sunset"]);
            if (sunset != null) {
                d.sunset = sunset;
                d.sunset_unix = f["sunset"];
            }
        }

        return d;
    };
    const hourly = response.hourly.map(processDatum);
    _indicateDailyMinMax(hourly);
    DATA = {
        current: processDatum(response.current),
        prediction: response.prediction,
        hourly: hourly,
        daily: response.daily.map(processDatum)
    };

    // Update UI
    updateUI();
};

// _getBoundingElement searches `values` for the element `element` with
// `element.min <= x < element.max`.
function _getBoundingElement<T extends { min: number; max: number; }>(
    values: T[], x: number): T {
    for (let i = 0; i < values.length; ++i) {
        if (x >= values[i].min && x < values[i].max)
            return values[i];
    }
    return values[0]; // unexpected
};

type TempColor = {min: number, max: number, text: string, bg: string, fg: string};
type PrecipColor = {min: number, max: number, color: string};

// Temperature colors
// text is used for the current observation,
// bg is used as background in graph, and
// fg for the forground in the graph.
const TEMP_COLORS: TempColor[] = [
    {min:  30,       max: Infinity, text: "hsl(  0, 90%, 55%)", bg: "hsl(  0, 100%, 55%)", fg: "#FFF"},
    {min:  26,       max:  30,      text: "hsl( 20, 90%, 55%)", bg: "hsl( 20, 100%, 55%)", fg: "#FFF"},
    {min:  24,       max:  26,      text: "hsl( 30, 90%, 55%)", bg: "hsl( 30, 100%, 55%)", fg: "#FFF"},
    {min:  22,       max:  24,      text: "hsl( 40, 90%, 55%)", bg: "hsl( 40, 100%, 55%)", fg: "#FFF"},
    {min:  20,       max:  22,      text: "hsl( 55, 90%, 55%)", bg: "hsl( 60, 100%, 55%)", fg: "#000"},
    {min:  16,       max:  20,      text: "hsl( 80, 80%, 50%)", bg: "hsl( 80, 100%, 50%)", fg: "#000"},
    {min:  12,       max:  16,      text: "hsl(100, 80%, 60%)", bg: "hsl(100, 100%, 60%)", fg: "#000"},
    {min:  10,       max:  12,      text: "hsl(120, 80%, 70%)", bg: "hsl(120, 100%, 75%)", fg: "#000"},
    {min:   7,       max:  10,      text: "hsl(140, 80%, 70%)", bg: "hsl(140, 100%, 75%)", fg: "#000"},
    {min:   4,       max:   7,      text: "hsl(160, 80%, 70%)", bg: "hsl(160, 100%, 75%)", fg: "#000"},
    {min:   0,       max:   4,      text: "hsl(180, 80%, 70%)", bg: "hsl(180, 100%, 85%)", fg: "#000"},
    {min:  -5,       max:   0,      text: "hsl(200, 80%, 70%)", bg: "hsl(200, 100%, 80%)", fg: "#FFF"},
    {min: -10,       max:  -5,      text: "hsl(220, 80%, 60%)", bg: "hsl(220, 100%, 60%)", fg: "#FFF"},
    {min: -Infinity, max: -10,      text: "hsl(240, 80%, 55%)", bg: "hsl(240, 100%, 55%)", fg: "#FFF"},
];
// Precipitation colors
// Levels based on Wikipedia info.
// Color from Yun Liu's icon set = hsl(199, 74%, 53%)
const PRECIP_COLORS: PrecipColor[] = [
    {min: -Infinity, max:      2.5, color: "hsl(199, 74%, 70%)"},
    {min:       2.5, max:      7.6, color: "hsl(199, 74%, 53%)"},
    {min:       7.6, max:     50.0, color: "hsl(199, 74%, 40%)"},
    {min:      50.0, max: Infinity, color: "hsl(199, 74%, 30%)"},
];
