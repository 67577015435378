// Widths
type Width = {
    readonly MARGIN_L: number,
    CHART: number,
    readonly MARGIN_R: number,
    readonly MAX_TOTAL: number,
    readonly PADDING_ON_BODY: number,
    readonly ICON: number,
}
const WIDTH: Width = {
    MARGIN_L: 20,
    CHART: 610, // Width of chart (w/o margins). Changes based on viewport width
    MARGIN_R: 10,
    MAX_TOTAL: 700, // Maximum total width (incl margins)
    PADDING_ON_BODY: 10, // padding-left+right of <body> (from style.css)
    ICON: 60,
}

// Heights
type Heights = "TOTAL" | "TOP" | "TEMP" | "SEPARATOR" | "PRECIP" | "TICKS" |
    "DESCRIPTIONS" | "ICON" | "BOTTOM";
const HEIGHT: {readonly [H in Heights]: number} = {
    TOTAL: 390,
    TOP: 20,
    TEMP: 150,
    SEPARATOR: 24,
    PRECIP: 100,
    TICKS: 22,
    DESCRIPTIONS: 80,
    ICON: 60,
    BOTTOM: 10,
};

// SVG context for D3.js
type Svg = d3.Selection<SVGElement, {}, null, undefined>;
const SVG: Svg = function () {
    const element = document.getElementById("chart") as unknown as SVGElement;
    return d3.select(element);
}();

// initUI initialized the user interface.
function initUI() {
    d3.select(window).on("resize", function () {
        _setSize();
        updateChart();
    });
    _setSize();

    initNav();
}

// initNav initializes the #nav element's behavior, specifically clicking the
// info button and switching the language.
function initNav() {
    // info button
    const toggleInfo = function () {
        const infoEl = d3.select("#info");
        infoEl.classed("-visible", !infoEl.classed("-visible"));
    };
    d3.select("#nav > .info").on("click", toggleInfo);

    // language selector
    d3.selectAll("#lang-selector > li").on("click", function () {
        const node = d3.select(this);
        const lang = node.attr("data-val");
        switchLang(lang);
    });
};

// _setSize sets the width and height of the SVG element based on the window
// width.
function _setSize() {
    const total_width = Math.min(WIDTH.MAX_TOTAL,
        document.body.clientWidth - WIDTH.PADDING_ON_BODY);
    WIDTH.CHART = total_width - WIDTH.MARGIN_L - WIDTH.MARGIN_R;
    SVG.attr("width", total_width)
        .attr("height", HEIGHT.TOTAL);
};

// On d3 selections, use `selection.call(_translate, x, y)` to add the attribute
// `transform` to the selection with value `translate(x, y)`. `x` and `y` can be
// constants or functions that take the data element associated with the d3
// node.
function _translate<GElement extends d3.BaseType, Datum, PElement extends d3.BaseType, PDatum>(
    node: d3.Selection<GElement, Datum, PElement, PDatum>,
    x: number | ((datum: Datum) => number),
    y: number | ((datum: Datum) => number)) {
    const fx = typeof x === "function" ? x : () => x;
    const fy = typeof y === "function" ? y : () => y;
    node.attr("transform", (d) => "translate(" + fx(d) + ", " + fy(d) + ")");
};

// updateUI updates the UI.
function updateUI() {
    updateCurrent();
    updateChart();
};

// updateCurrent updates the current weather conditions.
function updateCurrent() {
    if (DATA.current == undefined)
        return;

    const current = DATA.current;
    const prediction = DATA.prediction;

    d3.select(".now .temperature").html(current.temp + "&deg;C")
        .style("color", current.text_color);
    d3.select(".now .description").html(current.description[LANG]);
    d3.select(".now .icon").html(
        "<img src='" + current.icon + "' width='100' height='100'>");

    const time = d3.timeFormat(text("last_update"))(current.time);
    // The string only contains hours and minutes, because we assume the time is
    // recent enough that we don't need the date part.
    d3.select(".last-update").html(time);

    d3.select(".prediction").html(prediction[LANG]);
};

// updateChart draws the chart.
function updateChart() {
    if (DATA.hourly == undefined)
        return;

    const hourly = DATA.hourly;

    SVG.selectAll("*").remove();

    const timeExtent = d3.extent(hourly, (d) => d.time);
    if (timeExtent[0] == undefined)
        return;
    const xScale = d3.scaleTime()
        .domain(timeExtent)
        .rangeRound([0, WIDTH.CHART]);

    // Ordered background to foreground
    if (DATA.daily != undefined)
        chartNights(DATA.daily, xScale);
    chartMouseBar(hourly, xScale);
    chartTemp(hourly, xScale);
    chartPrecip(hourly, xScale);
    chartAxis(hourly, xScale);
    chartDescriptions(hourly, xScale);
    chartMouseOver(hourly, xScale);
};

// _addGrid creates a grid to display a graph on.
function _addGrid<GElement extends d3.BaseType, Datum, PElement extends d3.BaseType, PDatum>(
    graph: d3.Selection<GElement, Datum, PElement, PDatum>, title: string,
    height: number, xScale: d3.ScaleTime<number, number>,
    yScale: d3.ScaleLinear<number, number>, nYTicks: number) {
    const xGrid = d3.axisBottom(xScale).tickSize(height).tickFormat(_ => "");
    graph.append("g").attr("class", "grid grid-x")
        .call(xGrid)
        .select(".domain").remove();
    const yGrid = d3.axisLeft(yScale).ticks(nYTicks).tickSize(-WIDTH.CHART);
    graph.append("g").attr("class", "grid grid-y")
        .call(yGrid)
        .select(".domain").remove();
    graph.append("text").attr("class", "graph-title")
        .attr("x", 5)
        .attr("y", -2)
        .text(title);
    // Add title last, so it is shown above grid but below data
};

// _addTransparentTooltips adds transparent tooltips to a graph.
function _addTransparentTooltips<GElement extends d3.BaseType, SDatum, PElement extends d3.BaseType, PDatum>(
    graph: d3.Selection<GElement, SDatum, PElement, PDatum>, data: Datum[],
    getX: (d: Datum) => number, getY: (d: Datum) => number,
    text: (d: Datum) => string) {
    graph.append("g").attr("class", "tooltips hover-items")
        .selectAll("text")
        .data(data).enter()
        .append("text").attr("class", "data-tooltip hover-item")
            .call(_translate, getX, getY)
            .html(text);
};

// _addTransparentTooltips adds tooltips with a colored foreground and
// background to a graph.
function _addTooltipsWithBackground<GElement extends d3.BaseType, SDatum, PElement extends d3.BaseType, PDatum>(
    graph: d3.Selection<GElement, SDatum, PElement, PDatum>, data: Datum[],
    getX: (d: Datum) => number, getY: (d: Datum) => number,
    text: (d: Datum) => string, fgColor: (d: Datum) => string,
    bgColor: (d: Datum) => string, cssClass: (d: Datum) => string) {
    // tooltips = <g> containing <text> and <rect>
    const cssClasses = (d: Datum) => "data-tooltip hover-item " + cssClass(d);
    const tooltips = graph.append("g").attr("class", "tooltips hover-items")
        .selectAll("g")
        .data(data).enter()
        .append("g").attr("class", cssClasses)
            .call(_translate, getX, (d: Datum) => {
                const y = getY(d);
                if (y < 20) return y + 21; // tooltips at the top
                else        return y - 11; // most tooltips
            });
    // First create texts, because we need to know their bounding box to add the
    // rects. However, afterwards raise the texts so they are shown over the
    // rects.
    const texts = tooltips.append("text").attr("class", "text")
        .style("fill", fgColor)
        .text(text);
    const bbox = (i: number) => texts.nodes()[i].getBBox();
    tooltips.append("rect").attr("class", "bg")
        .attr("rx", 2)
        .attr("ry", 2)
        .attr("x", (_d, i:number ) => bbox(i).x - 2)
        .attr("y", (_d, i:number ) => bbox(i).y)
        .attr("width", (_d, i:number ) => bbox(i).width + 4)
        .attr("height", (_d, i:number ) => bbox(i).height)
        .style("fill", bgColor);
    texts.raise();
};

// chartNights draws the shaded background for nights.
function chartNights(daily: Datum[], xScale: d3.ScaleTime<number, number>) {
    const gradient = SVG.append("defs").append("linearGradient")
        .attr("id", "gradient-day-night")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 1)
        .attr("y2", 0);
    // daily[0].sunrise > 0: it is now between 0:00 and sunrise
    // daily[0].sunrise < 0 && daily[0].sunset > 0: it is day (between sunrise and sunset)
    // daily[0].sunrise < 0 && daily[0].sunset < 0: it is now between sunset and 24:00
    daily.forEach((d) => {
        if (d.sunrise == undefined || d.sunset == undefined) return;
        gradient.append("stop")
            .attr("offset", (xScale(d.sunrise) - 5) / WIDTH.CHART)
            .attr("class", "night");
        gradient.append("stop")
            .attr("offset", (xScale(d.sunrise) + 20) / WIDTH.CHART)
            .attr("class", "day");
        gradient.append("stop")
            .attr("offset", (xScale(d.sunset) - 10) / WIDTH.CHART)
            .attr("class", "day");
        gradient.append("stop")
            .attr("offset", (xScale(d.sunset) + 15) / WIDTH.CHART)
            .attr("class", "night");
    });

    const x = WIDTH.MARGIN_L;
    const y = HEIGHT.TOP;
    const height = HEIGHT.TEMP + HEIGHT.SEPARATOR + HEIGHT.PRECIP;
    SVG.append("rect").attr("class", "day-night")
        .attr("x", x)
        .attr("y", y)
        .attr("width", WIDTH.CHART)
        .attr("height", height)
        .style("fill", "url(#gradient-day-night)");
};

// chartTemp draws the temperature chart.
function chartTemp(data: Datum[], xScale: d3.ScaleTime<number, number>) {
    const graph = SVG.append("g").attr("class", "graph temp")
        .call(_translate, WIDTH.MARGIN_L, HEIGHT.TOP);

    let min = d3.min(data, (d) => d.temp);
    if (min == undefined) min = 0;
    let max = d3.max(data, (d) => d.temp);
    if (max == undefined) max = 20;
    const yScale = d3.scaleLinear()
        .domain([Math.floor(min), Math.ceil(max)])
        .rangeRound([HEIGHT.TEMP, 0]);
    const getX = (d: Datum): number => xScale(d.time);
    const getY = (d: Datum): number => yScale(d.temp);

    // grid
    _addGrid(graph, text("temp_title"), HEIGHT.TEMP, xScale, yScale, 5);

    // data line
    graph.append("path").attr("class", "data-line")
        .datum(data)
        .attr("d", d3.line<Datum>().x(getX).y(getY));

    // data points
    graph.append("g").attr("class", "points")
        .selectAll("circle")
        .data(data).enter()
        .append("circle").attr("class", "data-point")
            .attr("cx", getX)
            .attr("cy", getY)
            .attr("r", 3)
            .attr("fill", (d: Datum) => d.temp_bgcolor);

    // data tooltips
    const classForMinMax = (d: Datum) => {
        if (d.max || d.min) return "-on-mouse-out";
        return "";
    };
    _addTooltipsWithBackground(graph, data, getX, getY,
        (d: Datum) => d.temp + "\u00B0C",
        (d: Datum) => d.temp_fgcolor,
        (d: Datum) => d.temp_bgcolor,
        classForMinMax);
};

// chartPrecip draws the precipitation chart.
function chartPrecip(data: Datum[], xScale: d3.ScaleTime<number, number>) {
    const graph = SVG.append("g").attr("class", "graph precip")
        .call(_translate, WIDTH.MARGIN_L,
            HEIGHT.TOP + HEIGHT.TEMP + HEIGHT.SEPARATOR);

    let max = d3.max(data, (d) => d.precip);
    if (max == undefined) max = 0;
    const yScale = d3.scaleLinear()
        .domain([0, Math.max(6, Math.ceil(max))])
        .rangeRound([HEIGHT.PRECIP, 0]);
    const getX = (d: Datum) => xScale(d.time);
    const getY = (d: Datum) => yScale(d.precip);

    // grid
    _addGrid(graph, text("precip_title"), HEIGHT.PRECIP, xScale, yScale, 4);

    // bars
    const barWidth = WIDTH.CHART / (data.length - 1) + 0.8;
    // Add 0.8 to make bars fully overlap (no white margins)
    graph.append("g").attr("class", "bars")
        .selectAll("rect")
        .data(data).enter()
        .append("rect").attr("class", "data-bar")
            .attr("x", (d: Datum) => getX(d) - (barWidth / 2))
            .attr("width", barWidth)
            .attr("y", getY)
            .attr("height", (d: Datum) => HEIGHT.PRECIP - getY(d))
            .attr("fill", (d: Datum) => d.precip_color);

    // data tooltips
    const text_template1 = text("precip_label1");
    const text_template2 = text("precip_label2");
    _addTransparentTooltips(graph, data, getX, getY,
        (d: Datum) => {
            const txt1 = text_template1.replace("{}", d.precip_chance.toString());
            const txt2 = text_template2.replace("{}", d.precip.toString());
            const line1 = "<tspan x='0' dy='-2em'>" + txt1 + "</tspan>";
            const line2 = "<tspan x='0' dy='1.2em'>" + txt2 + "</tspan>";
            return line1 + line2;
        });
};

// chartAxis draws the x axis of the charts.
function chartAxis(data: Datum[], xScale: d3.ScaleTime<number, number>) {
    const xAxis = d3.axisBottom<Date>(xScale).tickFormat(TIME_FORMAT);
    if (WIDTH.CHART >= 600)
        xAxis.ticks(d3.timeHour.every(6));
    else if (WIDTH.CHART >= 300)
        xAxis.ticks(d3.timeHour.every(12));
    else
        xAxis.ticks(d3.timeHour.every(24));
    SVG.append("g").attr("class", "axis axis-x")
        .call(_translate, WIDTH.MARGIN_L, HEIGHT.TOP + HEIGHT.TEMP +
            HEIGHT.SEPARATOR + HEIGHT.PRECIP)
        .call(xAxis)
        .select(".domain").remove();
};

// chartDescriptions draws the descriptions underneath the chart.
function chartDescriptions(data: Datum[], xScale: d3.ScaleTime<number, number>) {
    const descriptions = SVG.append("g")
        .attr("class", "descriptions hover-items")
        .call(_translate, WIDTH.MARGIN_L,
            HEIGHT.TOP + HEIGHT.TEMP + HEIGHT.SEPARATOR +
            HEIGHT.PRECIP + HEIGHT.TICKS)
        .selectAll("g")
        .data(data).enter()
        .append("g").attr("class", "description hover-item");

    const negativeMarginTop = -8; // because icons have white space at the top
    descriptions.append("image").attr("class", "mouse-icon")
        .attr("x", (d: Datum) => xScale(d.time) - 30)
        .attr("y", negativeMarginTop)
        .attr("width", WIDTH.ICON)
        .attr("height", HEIGHT.ICON)
        .attr("xlink:href", (d: Datum) => d.icon);

    descriptions.append("text").attr("class", "mouse-description mouse-text")
        .attr("x", (d: Datum) => xScale(d.time))
        .attr("y", negativeMarginTop + HEIGHT.ICON + 3)
        .text((d: Datum) => d.description[LANG]);

    descriptions.append("text").attr("class", "mouse-time mouse-text")
        .attr("x", (d: Datum) => xScale(d.time))
        .attr("y", negativeMarginTop + HEIGHT.ICON + 15)
        .text((d: Datum) => TIME_FORMAT(d.time));

    descriptions.each(function () {
        const bbox = this.getBBox();
        const overflow = 3;
        d3.select(this).insert("rect").attr("class", "border")
            .attr("x", bbox.x - overflow)
            .attr("y", 0)
            .attr("width", bbox.width + 2*overflow)
            .attr("height", 2)
            .lower();
    });

    // If node is outside viewport on the left or right side, adjust its x
    // position so it stays in.
    descriptions.each(function () {
        const position = this.getBoundingClientRect();
        if (position.right > document.body.clientWidth) {
            const dx = document.body.clientWidth - position.right;
            d3.select(this).call(_translate, dx, 0);
        }
        if (position.left < 0) {
            d3.select(this).call(_translate, -position.left, 0);
        }
    });
};

// chartMouseBar draws the vertical bar shown when the mouse moves over
// the chart.
function chartMouseBar(data: Datum[], _xScale: d3.ScaleTime<number, number>) {
    const width = WIDTH.CHART / (data.length - 1);
    // Subtract 1, as last datum does not have space following it
    const height = HEIGHT.TEMP + HEIGHT.SEPARATOR + HEIGHT.PRECIP +
        HEIGHT.TICKS;
    SVG.append("rect").attr("class", "mouse-bar")
        .call(_translate, WIDTH.MARGIN_L - width / 2, HEIGHT.TOP)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height);
};

// chartMouseOver draws the field over which to "catch" mouse movements.
// This needs to be the top element.
function chartMouseOver(data: Datum[], _xScale: d3.ScaleTime<number, number>) {
    const barWidth = WIDTH.CHART / (data.length - 1);
    // Subtract 1, as last datum does not have space following it

    // Cache these to avoid having to find these elements each time the mouse
    // moves.
    const mouseBar = d3.select(".mouse-bar");
    const tempPoints = _d3SelectionAsArray(
        d3.selectAll(".graph.temp .data-point"));
    const itemContainers = _d3SelectionAsArray(d3.selectAll(".hover-items"))
        .map((itemContainer) => {
            return _d3SelectionAsArray(itemContainer.selectAll(".hover-item"));
        });
    // Keep track of bar that is currently being hovered to avoid redrawing when
    // the mouse moves over the bar without changing to the next position.
    let previousI: number | null = null;
    const hide = (i: number) => {
        itemContainers.forEach((items) => {
            items[i].classed("-hover", false);
        });
        tempPoints[i].attr("r", 3);
    };
    const show = (i: number) => {
        itemContainers.forEach((items) => {
            items[i].classed("-hover", true);
        });
        tempPoints[i].attr("r", 4);
    };
    const onmouseout = function() {
        if (previousI == null) return;
        SVG.classed("-hover", false);
        SVG.classed("-hide-tick-y", false);
        hide(previousI);
        previousI = null;
    };
    const onmousemove = function(this: SVGRectElement, event: Event) {
        let exactX: number | null = null;
        const pointer = d3.pointer(event); // Can be mouse or touch
        if (pointer) exactX = pointer[0];
        if (!exactX) return onmouseout();

        const i = Math.round(exactX / barWidth);
        if (i < 0 || i >= data.length) return onmouseout();
        const x = i * barWidth;

        if (i == previousI) return;
        onmouseout();

        SVG.classed("-hover", true);
        mouseBar.attr("x", x);
        // Close to the edge, fade out the tick labels
        if (x < 40) SVG.classed("-hide-tick-y", true);

        if (previousI != null) hide(previousI);
        show(i);

        previousI = i;
    };

    SVG.append("g").attr("class", "mouseover")
        .call(_translate, WIDTH.MARGIN_L, 0)
        .append("rect")
        .attr("width", WIDTH.CHART)
        .attr("height", HEIGHT.TOTAL)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("mouseout", onmouseout)
        .on("mousemove", onmousemove)
        .on("touchmove", onmousemove);
};
