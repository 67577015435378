/// <reference types="d3" />
/// <reference path="util.ts" />
/// <reference path="localization.ts" />
/// <reference path="data.ts" />
/// <reference path="ui.ts" />

// init initializes the application. It is called when this script is executed.
function init() {
    setLang();
    initUI();
};

init();
